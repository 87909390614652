<template>
    <validation-provider
        ref="validation-provider"
        :rules="required ? 'required' : rules"
        :name="name || label"
        v-bind="$attrs"
        v-slot="{ errors, valid, invalid, validated }"
    >
        <b-form-group>
            <slot name="label">
                <label v-if="label" :class="labelClasses">
                    {{ label }}
                </label>
            </slot>
            <div
                :class="[
                    { 'input-group': hasIcon },
                    { focused: focused },
                    { 'input-group-alternative': alternative },
                    { 'has-label': label || $slots.label },
                    inputGroupClasses,
                    'input-type-head'
                ]"
            >
                <div
                    v-if="prependIcon || $slots.prepend"
                    class="input-group-prepend"
                >
                    <span class="input-group-text">
                        <slot name="prepend">
                            <i :class="prependIcon"></i>
                        </slot>
                    </span>
                </div>
                <input type="hidden" :value="value" :name="name">
                <input
                    v-model="query"
                    v-on="listeners"
                    v-bind="$attrs"
                    :valid="valid"
                    :required="required"
                    class="form-control"
                    @keydown.down="inputKeyArrowDown"
                    @keydown.up="inputKeyArrowUp"
                    @keydown.enter="inputKeyEnter"
                    :class="[
                        { 'is-valid': valid && validated && successMessage,},
                        { 'is-invalid': invalid && validated },
                        inputClasses,
                    ]"
                />
                <div class="list-container" v-show="isShowSuggestList">
                    <div class="suggestion-list" ref="suggestion-list">
                        <div 
                            ref="items"
                            v-for="(item, index) in data" 
                            :key="index+ 'my-index'" 
                            :index="index"
                            :id="`list-item-${item[valueKey]}`"
                            :class="`list-item ${index == activeIndex ? 'active-item' : ''}`" 
                            @click="onItemClick(index, $el)"
                            @mouseover="onMouseOverItem(index)"
                            @mouseleave="onMouseLeaveItem(index)"
                        >
                            <slot name="item" v-bind="{item}">
                                {{ item[textKey] }}
                            </slot>
                        </div>
                    </div>
                </div>

                <div
                    v-if="appendIcon || $slots.append"
                    class="input-group-append"
                >
                    <span class="input-group-text">
                        <slot name="append">
                            <i :class="appendIcon"></i>
                        </slot>
                    </span>
                </div>
            </div>
            <slot name="success">
                <div
                    class="valid-feedback"
                    v-if="valid && validated && successMessage"
                >
                    {{ successMessage }}
                </div>
            </slot>
            <slot name="error">
                <div
                    v-if="errors[0]"
                    class="invalid-feedback"
                    style="display: block"
                >
                    {{ errors[0] }}
                </div>
            </slot>
        </b-form-group>
    </validation-provider>
</template>
<script>
export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
        required: {
            type: Boolean,
            default: false,
            description: "Whether input is required (adds an asterix *)",
        },
        group: {
            type: Boolean,
            description:
                "Whether input is an input group (manual override in special cases)",
        },
        alternative: {
            type: Boolean,
            description: "Whether input is of alternative layout",
        },
        label: {
            type: String,
            description: "Input label (text before input)",
        },
        error: {
            type: String,
            description: "Input error (below input)",
        },
        successMessage: {
            type: String,
            description: "Input success message",
            default: "",
        },
        labelClasses: {
            type: String,
            description: "Input label css classes",
            default: "form-control-label",
        },
        inputClasses: {
            type: String,
            description: "Input css classes",
        },
        inputGroupClasses: {
            type: String,
            description: "Input group css classes",
        },
        value: {
            type: [String, Number],
            description: "Input value",
        },
        appendIcon: {
            type: String,
            description: "Append icon (right)",
        },
        prependIcon: {
            type: String,
            description: "Prepend icon (left)",
        },
        rules: {
            type: [String, Array, Object],
            description: "Vee validate validation rules",
            default: "",
        },
        name: {
            type: String,
            description: "Input name (used for validation)",
            default: "",
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        textKey: {
            type: String,
            default: 'text'
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        visibleItem: {
            type: Number,
            default: null
        },
        isSearchLocalMode: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            focused: false,
            activeIndex: -1,
            query: null,
            isMouseOverItem: false,
            isInitQuery: true
        };
    },
    updated() {
        if (this.value && this.data && this.isInitQuery) {
            let selectedItem = document.getElementById(`list-item-${this.value}`)
            if (selectedItem) {
                this.isInitQuery = false
                this.query = selectedItem.textContent.trim()
                this.activeIndex = selectedItem.attributes.index.value
            }
        }
    },
    computed: {
        isShowSuggestList() {
            return this.data.length && this.focused
        },
        listeners() {
            return {
                ...this.$listeners,
                click: this.inputClick,
                blur: this.onBlur,
                input: this.input
            };
        },
        slotData() {
            return {
                focused: this.focused,
                error: this.error,
                ...this.listeners,
                click: this.focused
            };
        },
        hasIcon() {
            const { append, prepend } = this.$slots;
            return (
                append !== undefined ||
                prepend !== undefined ||
                this.appendIcon !== undefined ||
                this.prependIcon !== undefined ||
                this.group
            );
        },
    },
    methods: {
        inputKeyEnter() {
            if (this.activeIndex >= 0 && this.activeIndex < this.data.length ) {
                this.onItemClick(this.activeIndex)
            }
        },
        inputKeyArrowDown() {
            if (!this.focused) {
                this.focused = true
                return
            } else if (this.activeIndex < this.data.length - 1 ) {
                this.activeIndex ++
            }
        },
        inputKeyArrowUp() {
            if (!this.focused) {
                return
            } else if (this.activeIndex > 0) {
                this.activeIndex --
            }
        },
        input(evt) {
            let text = evt.target.value.toLowerCase();
            if (!text) {
                return
            }

            let children = this.$refs.items
            let isHasMatchData = false
            children.forEach(item => {
                let textContent = item.textContent.trim().toLowerCase()
                if (textContent.includes(text)) {
                    item.style.display = null
                    isHasMatchData = true
                } else {
                    item.style.display = 'none'
                }
            })        

            if (!isHasMatchData) {
                this.$emit('onNoDataMatched')
            }
        },
        inputClick(evt) {
            this.focused = true
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            if (this.isMouseOverItem) {
                return;
            }
            this.focused = false;
            this.$emit("blur", evt);
        },
        onItemClick(index) {
            let item = this.data[index]
            let value = item[this.valueKey]
            this.isMouseOverItem = false
            this.focused = false
            
            let selectedItem = document.getElementById(`list-item-${value}`)
            this.query = selectedItem.textContent.trim()
            this.$emit('input', value)
            this.$emit('onOptionClick', item)  
        },
        onMouseOverItem(index) {
            this.isMouseOverItem = true
            this.activeIndex = index
        },
        onMouseLeaveItem() {
            this.isMouseOverItem = false
        }
    }
};
</script>
